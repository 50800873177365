<template>
  <v-list dense nav>
    <template v-for="task in tasks">
      <v-list-item :key="`task-${task.taskId}`" :to="createTargetRoute(task)">
        <!-- Task icon -->
        <v-list-item-avatar>
          <task-icon :task="task"></task-icon>
        </v-list-item-avatar>

        <!-- content -->
        <v-list-item-content>
          <!-- Task name -->
          <v-list-item-title>{{ task.name }}</v-list-item-title>

          <!-- Associated project name -->
          <v-list-item-subtitle>{{ task.projectName }}</v-list-item-subtitle>

          <!-- Task status -->
          <v-list-item-subtitle>
            <task-status :task="task"></task-status>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="mr-2">
          <v-simple-checkbox
            dense
            @click="handleCheckboxClick($event, task)"
            :value="isSelected(task)"
          ></v-simple-checkbox>
        </v-list-item-action>
      </v-list-item>

      <v-divider :key="`task-divider-${task.taskId}`"></v-divider>
    </template>
  </v-list>
</template>

<script>
// model
import { moduleNames } from "@/model/solution/moduleModel";

// router
import { createModuleItemContentRouteLocation } from "@/router/solution/solutionRoutes";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "TaskList",
  components: {
    TaskIcon: () => import("@/components/task/TaskIcon"),
    TaskStatus: () => import("@/components/task/TaskStatus")
  },
  props: {
    /**
     * @type {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}[]}
     */
    tasks: undefined,
    selectAllTasks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedTasks: []
    };
  },
  methods: {
    /**
     * create Task Target Route
     * @param {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}} task
     */
    createTargetRoute(task) {
      const id = task?.taskId ?? -1;

      return id >= 0
        ? createModuleItemContentRouteLocation(moduleNames.Tasks, id)
        : undefined;
    },

    /**
     * Event on Checkbox Clicked
     * @param event
     * @param task
     */
    handleCheckboxClick(event, task) {
      // To Stop Navigation
      event.preventDefault();

      const index = this.selectedTasks.indexOf(task);
      if (index > -1) {
        this.selectedTasks.splice(index, 1); // Remove from selectedTasks
        //this.emitTaskEvent(true);
      } else {
        this.selectedTasks.push(task); // Add to selectedTasks
        //this.emitTaskEvent();
      }
      this.emitTaskEvent(true);
    },

    /**
     * Determine if task is selected
     * @param task
     * @return {boolean}
     */
    isSelected(task) {
      return this.selectedTasks.includes(task);
    },

    /**
     * Emit task Event
     * @param setIndeterminate
     */
    emitTaskEvent(setIndeterminate = false) {
      const payload = {
        tasks: this.selectedTasks,
        setIndeterminate: setIndeterminate
      };

      this.$emit(eventNames.itemSelected, payload);
    }
  },

  watch: {
    selectAllTasks() {
      if (this.selectAllTasks) {
        this.selectedTasks = [...this.tasks];
      } else {
        this.selectedTasks = [];
      }
      this.emitTaskEvent();
    },

    tasks: {
      handler() {
        this.selectedTasks = [];
        this.emitTaskEvent();
      },
      deep: true
    }
  }
};
</script>
